import React, { useEffect } from "react";

import "./client-list.scss";

const logos = [
    "/assets/images/clients/rabobank-logo.png",
    "/assets/images/clients/kpn-logo.png",
    "/assets/images/clients/politie-logo.png",
    "/assets/images/clients/kvk-logo.png",
    "/assets/images/clients/ahold-logo.png",
    "/assets/images/clients/van-lanschot-logo.png",
    "/assets/images/clients/stater-logo.png"
];

const Clients = () => {
    return (
        <section className="light-bg-2" data-midnight="black">
            <div className="container small top-bottom-padding-120">
                <h2 className="medium-title text-center text-color-5">
                    <span data-text="Our clients">Join our list of top clients</span>
                </h2>
                <ul className="client-list d-flex-wrap top-padding-60">
                    {logos.map((logo, i) => (
                        <SingleClient key={logo} index={i} logo={logo} />
                    ))}
                    <li className="empty-spot-box">
                        <a data-animation-container href="#contact" className="pointer-large p-style-bold-up empty-spot d-block">
                            <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="This spot">This spot</span>
                            <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Awaits">Awaits</span>
                            <span data-animation-child className="title-fill tr-delay02" data-animation="title-fill-anim" data-text="You">You</span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    );
};

interface Props {
    logo: string;
    index: number;
}

const SingleClient = (props: Props) => {
    const { logo, index, } = props;

    const id = "img-" + index;
    const canvasRedId = "canvas-red-" + index;
    const canvasGrayscaleId = "canvas-grayscale-" + index;
    const hasWindow = checkHasWindow();

    useEffect(() => {
        if (hasWindow) {
            handleImgLoad();
        }
    }, [hasWindow]);

    const canvasPosition: React.CSSProperties = { position: "absolute", top: 0, bottom: 0, width: 150, margin: "auto", };

    const handleImgLoad = () => {
        if (!hasWindow) {
            return;
        }
        const canvasRed = document.getElementById(canvasRedId) as HTMLCanvasElement | null;
        const canvasGrayscale = document.getElementById(canvasGrayscaleId) as HTMLCanvasElement | null;
        const srcImg = document.getElementById(id) as HTMLImageElement | null;

        if (!canvasRed || !canvasGrayscale || !srcImg) return;

        const height = srcImg.clientHeight;
        const width = srcImg.clientWidth;

        canvasRed.height = height;
        canvasRed.width = width;
        canvasGrayscale.height = height;
        canvasGrayscale.width = width;
        srcImg.crossOrigin = "Anonymous";

        grayscale(canvasGrayscale, srcImg);
        fillWithColor(canvasRed, srcImg);
    };

    const grayscale = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        // ctx.imageSmoothingEnabled = false;
        ctx.filter = "grayscale(1)";
        ctx.drawImage(image, 0, 0, image.width, image.height);
    };

    const fillWithColor = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        ctx.imageSmoothingEnabled = false;
        ctx.globalCompositeOperation = "destination-atop";
        ctx.fillStyle = "#ef0d33";

        ctx.fillRect(0, 0, canvas.width, canvas.height);  
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };

    return (
        <li>
            <a className="pointer-large d-block" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                <div className="brand-box">
                    <img id={id} src={logo} onLoad={handleImgLoad} style={{ position: "absolute", top: 0, opacity: 0, }} />
                    <canvas id={canvasRedId} style={{ ...canvasPosition, mixBlendMode: "screen", zIndex: 1, }} className="opac-img" />
                    <canvas id={canvasGrayscaleId} style={canvasPosition} />
                </div>
            </a>
        </li>
    );
};

const checkHasWindow = () => {
    const [isWindow, setIsWindow] = React.useState(false);
  
    React.useEffect(() => {
      
        setIsWindow(true);
  
        return ()=> setIsWindow(false);
    }, []);
  
    return isWindow;
};

export default Clients;
