import React from "react";

interface Props {
    backgroundImage: string;
    preTitle: string;
    title: string[];
}

const HeroSplit = (props: Props) => {
    const { backgroundImage, preTitle, title, } = props;

    const delayStart = preTitle ? 3 : 4;
    const getTitleDelay = (index: number) => index + delayStart;

    return (
        <section id="up" className="page-head flex-min-height-box dark-bg-2">
            <div className="page-head-bg overlay-loading2" style={{ backgroundImage: `url(${backgroundImage})`, }}></div>
    
            <div className="flex-min-height-inner">
                <div className="container top-bottom-padding-120 flex-container response-999">
                    <div className="six-columns six-offset">
                        <div className="content-left-margin-40">
                            {!!preTitle &&
                                <h2 className="overlay-loading2 tr-delay03 medium-title red-color">{preTitle}</h2>
                            }
                            <h3 className="large-title-bold text-color-4">
                                {title.map((title, i) => (
                                    <><span key={i} className={`overlay-loading2 overlay-light-bg-1 tr-delay0${getTitleDelay(i)}`}>{title}</span><br /></>
                                ))}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
      
            <a href="#down" className="scroll-btn pointer-large">
                <div className="scroll-arrow-box">
                    <span className="scroll-arrow"></span>
                </div>
                <div className="scroll-btn-flip-box">
                    <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                </div>
            </a>
        </section>
    );
};

export default HeroSplit;
