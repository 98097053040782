import React from "react";

const ContactForm = () => {
    return (
        <section id="contact" className="contact-form-box flex-min-height-box" style={{ backgroundImage: "url(/assets/images/contact/contact-form-background.jpg)", }}>
            <div className="bg-overlay"></div>
            <div className="flex-min-height-inner">
                <div className="contact-form-container">
                    <div className="container small top-bottom-padding-120 form-box">
                        <h4 className="small-title-oswald text-color-4 text-center">Got a question? Let's Get In Touch!</h4>
                        <form className="flex-container top-padding-90" method="post" name="formobrsv" id="send_form">
                            <div className="four-columns">
                                <div className="content-right-margin-10 input-box">
                                    <input type="text" name="first_name" id="first_name" required className="form-input pointer-small" />
                                    <label htmlFor="first_name" className="form-label">First name</label>
                                </div>
                            </div>
                            <div className="four-columns">
                                <div className="content-left-right-margin-5 input-box">
                                    <input type="text" name="last_name" id="last_name" required className="form-input pointer-small" />
                                    <label htmlFor="last_name" className="form-label">Last name</label>
                                </div>
                            </div>
                            <div className="four-columns">
                                <div className="content-left-margin-10 input-box">
                                    <input type="email" name="email" id="email" required className="form-input pointer-small" />
                                    <label htmlFor="email" className="form-label email-label">Email address</label>
                                </div>
                            </div>
                            <div className="twelve-columns input-box message-input">
                                <textarea name="message" id="message" required className="form-input pointer-small"></textarea>
                                <label htmlFor="message" className="form-label">Message content</label>
                            </div>
                            <div className="twelve-columns text-center top-padding-90">
                                <button id="send" className="border-btn-box pointer-large">
                                    <span className="border-btn-inner">
                                        <span className="border-btn" data-text="submit">submit</span>
                                        <span className="btn-wait">Wait...</span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="js-popup-fade" id="m_sent">
                        <div className="js-popup text-center">
                            <div className="popup-alert title-style text-color-4">
                            Thank you!<br />
                            Your submission<br />
                            has been received!
                            </div>
                            <div className="flip-btn-box js-popup-close">
                                <div className="flip-btn pointer-large" data-text="Close">Close</div>
                            </div>
                        </div>
                    </div>

                    <div className="js-popup-fade" id="m_err">
                        <div className="js-popup text-center">
                            <div className="popup-icon">
                                <i className="fas fa-times"></i>
                            </div>
                            <div className="popup-alert title-style text-color-4">Error</div>
                            <div className="flip-btn-box js-popup-close">
                                <div className="flip-btn pointer-large" data-text="Close">Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
