import React from "react";
import Clients from "../components/clients/Clients";
import ContactForm from "../components/contact-form/ContactForm";
import HeroSplit from "../components/hero/HeroSplit";

const aboutText = "At our core, we are career builders. Our driving force is finding talented IT professionals and taking their career to the next level. Through our extensive network we provide unique growth opportunities that help them meet their professional goals.<br><br>"
    + "Our talent pool is a varied collection of developers, testers and managers with a proven track record of excellence. We give them the ability to build the diverse portfolio of a freelancer while maintaining financial stability. As a result, our workers are highly experienced working in diverse environments, and adapt quickly to new challenges.";

const marketText = "We started out as a purely Dutch business, providing Dutch-speaking workers to prestigious institutions such as Rabobank, the Dutch Police and KPN. Now, to keep up with the demands of an increasingly virtual and competitive world, we are expanding our business across the continent by offering remote labor.<br><br>"
    + "Our remote workforce enables us to offer incredibly competitive prices, so we can cater to the needs of businesses of all sizes and stages.";

const advantageText = "In addition to our dedication to our candidates’ professional development and competitive prices, we are always looking for ways to streamline our processes to provide the fastest service possible.<br><br>"
    + "As a part of this, we have developed our very own candidate management software. This gives us total control over our candidates’ availability, experience and skillset, so they can quickly be matched with new assignments. This year we will open up our software to our clients, giving them direct access to available talent.";

const About = () => {
    return (
        <>
            <HeroSplit preTitle="Get to know us" title={["We are your", "next IT service", "provider"]} backgroundImage="/assets/images/about/about-header.jpg" />
            <section id="down" className="dark-bg-1 flex-min-height-box about-page">
                <div className="flex-min-height-inner" style={{ flexDirection: "column", }}>
                    <TextBlock title="Who are we?" text={aboutText} />
                    <TextBlock title="Where do we operate?" text={marketText} />
                    <TextBlock title="What's our advantage?" text={advantageText} />
                </div>
            </section>

            <Clients />
            <ContactForm />
        </>
    );
};

interface Props {
    title: string;
    text: string;
}

const TextBlock = (props: Props) => {
    return (
        <div className="container top-bottom-padding-120">
            <div data-animation-container className="flex-container">
                <div className="four-columns">
                    <div className="content-right-margin-20">
                        <h2 data-animation-child className="title-style title-fill" data-animation="title-fill-anim" data-text={props.title}>{props.title}</h2>
                    </div>
                </div>
                <div className="eight-columns">
                    <div className="content-left-margin-10">
                        <p data-animation-child className="p-style-large text-color-5 fade-anim-box tr-delay01" data-animation="fade-anim" dangerouslySetInnerHTML={{ __html: props.text, }} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default About;
